import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isNull from 'lodash/isNull';
import { convertDateInTimezone } from '../../../utils/methods';
import { TodaysChallengeDiv, DateContainer, ChallengeContainer, RightArrow } from './styles';

class TodaysChallenge extends Component {
  constructor() {
    super();
    this.state = {
      renderTip: true,
      challengeDay: '',
      challengeMonth: '',
      eventDay: '',
      eventMonth: ''
    };
  }

  componentDidMount() {
    this.getChallengeStartDate();
  }

  UNSAFE_componentWillReceiveProps() {
    this.getChallengeStartDate();
  }

  getChallengeStartDate = () => {
    const { activeChallenge, event, upComingEvent} = this.props;
    if(!event && !isNull(activeChallenge)) {
      let date = moment(convertDateInTimezone(activeChallenge.start_date)._d, 'MM DD YYYY').format('DD,MMMM,YYYY');
      date = date && date.split(',');
      this.setState({
        challengeDay: date[0],
        challengeMonth: date[1]
      })
    }
    if(event && upComingEvent && upComingEvent.length > 0){
      let date = moment(convertDateInTimezone(upComingEvent[0].start_date)._d, 'MM DD YYYY').format('DD,MMMM,YYYY');
      date = date && date.split(',');
      this.setState({
        eventDay: date[0],
        eventMonth: date[1]
      })
    }
  };

  render() {
    const { challengeDay, challengeMonth, eventDay, eventMonth } = this.state;
    const { activeChallenge, history, event, upComingEvent} = this.props;
    return (
      <div>
        { event ?
          upComingEvent && upComingEvent.length > 0 ?
            <TodaysChallengeDiv bgColor={event} onClick={() => history.push(`/events/${upComingEvent[0].id}`)}>
              <DateContainer>
                <div>
                  <span>{eventMonth}</span>
                </div>
                <div className="year">{eventDay}</div>
              </DateContainer>
              <ChallengeContainer>
                <div>{"Upcoming Event"}</div>
                <div>{upComingEvent[0].title}</div>
              </ChallengeContainer>
              <div className="continue">
                <span>MORE</span>
                <RightArrow className="fas fa-angle-right"/>
              </div>
            </TodaysChallengeDiv> : null
          :
          !isNull(activeChallenge) ?
            <TodaysChallengeDiv onClick={() => history.push(`/challenges/${activeChallenge.id}`)}>
              <DateContainer>
                <div>
                  <span>{challengeMonth}</span>
                </div>
                <div className="year">{challengeDay}</div>
              </DateContainer>
              <ChallengeContainer>
                <div>{"Today's Challenge"}</div>
                <div>{activeChallenge.title}</div>
              </ChallengeContainer>
              <div className="continue">
                <span>MORE</span>
                <RightArrow className="fas fa-angle-right"/>
              </div>
            </TodaysChallengeDiv> : null
        }
      </div>
    )
  }
}

TodaysChallenge.propTypes = {
  history: PropTypes.object.isRequired,
  activeChallenge: PropTypes.object,
  upComingEvent: PropTypes.array,
  event: PropTypes.number
};

export default TodaysChallenge;
