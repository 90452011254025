import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {  DailySummaryDiv, DailySummaryHeader, ButtonsContainer, TileButton, ContentContainer, OverviewContainer,
  InnerActivityBar, LoaderContainer, ActivityBar, CompleteInformation, CompleteInformationBox } from './styles';
import { Steps, Calories, HolidayMap } from '../../SvgComponentsV2';
import Loading from '../../Loading';
import { getActivityOverview } from '../../../redux/actions';
import { commaSeperator } from '../../../utils/methods';

class YourActivityOverview extends Component {
  constructor() {
    super();
    this.state = {
      date: new Date(),
      selected: 'month'
    };
  }

  getStartDate = (date, value) => moment(date).startOf(value).format('MM-DD-YYYY');
  getEndDate = (date, value) => moment(date).endOf(value).format('MM-DD-YYYY');

  componentDidMount() {
    const { date } = this.state;
    this.getActivityOverviewData(this.getStartDate(date, 'month'), this.getEndDate(date, 'month'));
  }

  getActivityOverviewData = (startDate, endDate) => {
    this.props.fetchActivityOverview(startDate, endDate)
  };

  onClick = (value) => {
    this.setState({
      selected: value
    });
    this.getActivityOverviewData(this.getStartDate(this.state.date, value), this.getEndDate(this.state.date, value));
  };

  render() {
    const { selected } = this.state;
    const { isLoading, activityOverview } = this.props;

    return (
      <DailySummaryDiv isYourActivityOverview position={"relative"}>
        <DailySummaryHeader>
          <span>Your activity overview</span>
          <ButtonsContainer>
            <TileButton
              onClick={() => this.onClick('week')}
              active={selected === 'week'}
            >
              Week
            </TileButton>
            <TileButton
              onClick={() => this.onClick('month')}
              active={selected === 'month'}
            >
              Monthly
            </TileButton>
            <TileButton
              onClick={() => this.onClick('year')}
              active={selected === 'year'}
            >
              Year
            </TileButton>
          </ButtonsContainer>
          <ContentContainer>
            {
              isLoading ? <LoaderContainer><Loading /></LoaderContainer> :
                <div>
                  <OverviewContainer>
                    <Steps marginTop="13px" color="#32DBC6"/>
                    <ActivityBar>
                      <InnerActivityBar
                        color="#32DBC6"
                        width={`${_.get(activityOverview, 'percent_steps', '0') > 100 ? 100 : _.get(activityOverview, 'percent_steps', '0')}%`}
                        isBigBar
                      />
                    </ActivityBar>
                    <CompleteInformationBox>
                      <CompleteInformation>
                        <div>{parseFloat(Math.round((_.get(activityOverview, 'distance', '0')) * 100) / 100).toFixed(1)}miles</div>
                      </CompleteInformation>
                      <CompleteInformation padding={"0px 10px 0 0"}>
                          Total Distance
                      </CompleteInformation>
                    </CompleteInformationBox>
                  </OverviewContainer>
                  <OverviewContainer>
                    <Calories marginTop="13px" color="#FF9104"/>
                    <ActivityBar>
                      <InnerActivityBar
                        color="#FF9104"
                        width={`${_.get(activityOverview, 'percent_calories', '0') > 100 ? 100 : _.get(activityOverview, 'percent_calories', '0')}%`}
                        isBigBar
                      />
                    </ActivityBar>
                    <CompleteInformationBox>
                      <CompleteInformation>
                        <div>{commaSeperator(parseInt(_.get(activityOverview, 'calories', '0')))}</div>
                      </CompleteInformation>
                      <CompleteInformation padding={"2px 3px 0 0"}>
                      Calories Burned
                      </CompleteInformation>
                    </CompleteInformationBox>
                  </OverviewContainer>
                  <OverviewContainer>
                    <HolidayMap marginTop="13px" color="#59B7E6"/>
                    <ActivityBar>
                      <InnerActivityBar
                        color="#59B7E6"
                        width={`${_.get(activityOverview, 'percent_distance', '0') > 100 ? 100 : _.get(activityOverview, 'percent_distance', '0')}%`}
                        isBigBar
                      />
                    </ActivityBar>
                    <CompleteInformationBox>
                      <CompleteInformation>
                        <div>{commaSeperator(parseInt(_.get(activityOverview, 'steps', '0')))}</div>
                      </CompleteInformation>
                      <CompleteInformation padding={"2px 25px 0 0"}>
                        Total Steps
                      </CompleteInformation>
                    </CompleteInformationBox>
                  </OverviewContainer>
                </div>
            }
          </ContentContainer>
        </DailySummaryHeader>
      </DailySummaryDiv>
    )
  }
}

YourActivityOverview.propTypes = {
  activityOverview: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  fetchActivityOverview: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  activityOverview: state.wellnessDashboard.activityOverview,
  isLoading: state.wellnessDashboard.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchActivityOverview: (startDate, endDate) => dispatch(getActivityOverview(startDate, endDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(YourActivityOverview);
