import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  NavigationBox, DailyTip, Author } from './styles';
import { getDecodedString } from '../../../utils/methods';
import {MyFocusContainer, DailyTipHeader} from "../styles";
import DOMPurify from 'dompurify';

class TipNavigationBox extends Component {
  constructor() {
    super();
    this.state = {
      renderTip: true,
    };
  }

  toggleViews = (value) => {
    this.setState({
      renderTip: value
    });
  }

  createSafeHTML = (htmlString) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    const range = document.createRange();
    const fragment = range.createContextualFragment(sanitizedHTML);
    return fragment; 
  };

  myFocusSection = () => (
    <MyFocusContainer>
      <div>
        <img src={'/public/images/myfocus1.svg'} onClick={() => this.toggleViews(true)}/>
        <div>DAILY <br /> TIP </div>
      </div>
      <div>
        <img src={'/public/images/myfocus2.svg'} onClick={() => this.toggleViews(false)}/>
        <div>WEEKLY <br /> MOTIVATION </div>
      </div>
    </MyFocusContainer>
  );
  render() {
    const { renderTip } = this.state;
    const { dailyTip, motivationalQuote } = this.props;

    return (
      <React.Fragment>
        {this.myFocusSection()}
        <NavigationBox bgColor={renderTip}>
          <DailyTipHeader color={renderTip ? 1 : 0}>{renderTip === true ? 'DAILY TIP' : 'WEEKLY MOTIVATION'}</DailyTipHeader>
          { renderTip === true ?
            <DailyTip>
              {/* <span dangerouslySetInnerHTML={{ __html: getDecodedString(dailyTip.daily_tip) }} /> */}
              <span>{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(dailyTip.daily_tip)))}</span>
            </DailyTip> :
            <DailyTip>
              {/* <span dangerouslySetInnerHTML={{ __html: getDecodedString(motivationalQuote.quote) }} /> */}
              <span>{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(motivationalQuote.quote)))}</span>
              <Author>
                {`- ${motivationalQuote.author}`}
              </Author>
            </DailyTip>
          }
        </NavigationBox>
      </React.Fragment>
    )
  }
}

TipNavigationBox.propTypes = {
  dailyTip: PropTypes.object,
  motivationalQuote: PropTypes.object,
};

export default TipNavigationBox;
